import imgAdda from "./img/artists/Adda.jpg"
import imgBor from "./img/artists/Bor.jpg"
import imgGarcia from "./img/artists/Garcia.jpg"
import imgHadjab from "./img/artists/Hadjab.jpg"
import imgHerizi from "./img/artists/Herizi.jpg"
import imgIssiakhem from "./img/artists/Issiakhem.jpg"
import imgLegault from "./img/artists/Legault.jpg"
import imgMolinero from "./img/artists/Molinero.jpg"
import imgMomein from "./img/artists/Momein.jpg"
import imgTomek from "./img/artists/Tomek.jpg"
import imgPaounoff from "./img/artists/Paounoff.jpg"
import imgSortais from "./img/artists/Sortais.jpg"
import imgTersen from "./img/artists/Tersen.jpg"
import imgYarou from "./img/artists/Yarou.jpg"
import imgZohra from "./img/artists/Zohra.jpg"

const Artists = [
    {
      "first": "Kais",
      "last": "Adda",
      "img": imgAdda
    },
    {
      "first": "Lucian",
      "last": "Bor",
      "img": imgBor
    },
    {
      "first": "John",
      "last": "Garcia",
      "img": imgGarcia
    },
    {
      "first": "Karim",
      "last": "Hadjab",
      "img": imgHadjab
    },
    {
      "first": "Bayane",
      "last": "Herizi",
      "img": imgHerizi
    },
    {
      "first": "Ilies",
      "last": "Issiakhem",
      "img": imgIssiakhem
    },
    {
      "first": "Prosper",
      "last": "Legault",
      "img": imgLegault
    },
    {
      "first": "Anita",
      "last": "Molinero",
      "img": imgMolinero
    },
    {
      "first": "Nicolas",
      "last": "Momein",
      "img": imgMomein
    },
    {
      "first": "Yoan",
      "last": "Paounoff",
      "img": imgPaounoff
    },
    {
      "first": "Ken",
      "last": "Sortais",
      "img": imgSortais
    },
    {
      "first": "Melchior",
      "last": "Tersen",
      "img": imgTersen
    },
    {
      "first": "Pablo",
      "last": "Tomek",
      "img": imgTomek
    },
    {
      "first": "Usem",
      "last": "Yarou",
      "img": imgYarou
    },
    {
      "first": "",
      "last": "Zohra",
      "img": imgZohra
    }
]

export default Artists